@import "../scss/variables";
@charset "UTF-8";
.app-sidebar .mCSB_draggerContainer {
	right: -11px !important;
}
@media (min-width: 768px) {
	.app.sidenav-toggled {
		.app-content {
			margin-left: 80px;
		}
		.app-sidebar {
			left: 0;
			width: 80px;
			overflow: hidden;
		}
		.app-sidebar__overlay {
			visibility: hidden;
		}
		.slide.is-expanded .slide-menu {
			display: none;
		}
		.app-sidebar .main-sidebar-header .desktop-logo{
			display:none !important;
		}
	}
	.side-badge {
		display: none;
	}
	.side-header {
		width: 270px;
		left: 0;
		right: 0;
		top: 0;
		position: fixed;
		z-index: 1;
	}
}
@media (max-width: 767px) {
	.app {
		overflow-x: hidden;
		.app-sidebar {
			left: -270px;
		}
		.app-sidebar__overlay {
			visibility: hidden;
		}
		&.sidenav-toggled {
			.app-content {
				margin-left: 0;
			}
			.app-sidebar {
				left: 0;
			}
			.app-sidebar__overlay {
				visibility: visible;
			}
		}
		&.sidebar-gone.sidenav-toggled .app-sidebar {
			left: 0;
		}
	}
}
.app-content {
	min-height: calc(100vh - 50px);
	margin-bottom: 0 !important;
	/* -webkit-transition: margin-left 0.3s ease; */
	-o-transition: margin-left 0.3s ease;
	/* transition: margin-left 0.3s ease; */
	overflow: hidden;
	.side-app {
		padding: 0px 30px 0 30px;
	}
}
.footer .container {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}
@media (min-width: 768px) {
	.app-content {
		margin-left: 270px;
	}
	.app.sidenav-toggled .avatar-xl {
		width: 55px !important;
		height: 55px !important;
	}
	.app.sidebar-mini.sidenav-toggled .logo-icon {
		display: none !important;
	}
	.app.sidebar-mini.sidenav-toggled .logo-icon.icon-dark {
		display: none !important;
	}
	.app.sidebar-mini.sidenav-toggleddark-theme .logo-icon {
		display: none !important;
	}
	.app.sidebar-mini.sidenav-toggled.dark-theme .logo-icon.icon-dark {
		display: none !important;
	}
	.app.sidebar-mini .logo-icon {
		display: none;
	}
	.app.sidebar-mini.sidenav-toggled .desktop-logo {
		display: none;
	}
	.app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
		line-height: 19px;
	}
	.main-sidebar-header .app-sidebar__toggle {
		position: absolute;
		width: 30px;
		height: 30px;
		background: $black-1;
		right: 20px;
		border-radius: 50%;
		margin-right: 0;
		margin-left: 0;
		text-align: center;
		a {
			line-height: 1.5;
			.header-icon {
				color: $white;
				line-height: 30px;
			}
		}
	}
	.sidenav-toggled .main-sidebar-header .app-sidebar__toggle {
		right: 26px;
	}
	.sidenav-toggled .side-menu {
		padding: 0 10px;
	}
	.sidenav-toggled .app-sidefooter {
		width: 80px;
	}
}
@media (max-width: 767px) {
	.app-content {
		min-width: 100%;
	}
}
@media (max-width: 480px) {}
@media print {
	.app-content {
		margin: 0;
		padding: 0;
		background-color: $white;
	}
}
.light-text {
	font-weight: 300 !important;
}
.semibold-text {
	font-weight: 600 !important;
}
.line-head {
	padding-bottom: 10px;
	border-bottom: 1px solid #ddd;
}
/*----- Componant: Top Navigation Bar ----- */

.app-header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	z-index: 999;
	padding-right: 15px;
	padding: 5px 0;
	box-shadow: 0 2px 17px 2px $black-2;
	border-bottom: 1px solid$white-1;
	background: #5e2dd8;
}
@media (min-width: 768px) {
	.app-header {
		padding-right: 30px;
	}
}
@media print {
	.app-header {
		display: none;
	}
}
.app-header__logo {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	color: $white;
	text-align: center;
	font-family: 'Niconne';
	padding: 0 15px;
	font-size: 26px;
	font-weight: 400;
	line-height: 50px;
	&:focus, &:hover {
		text-decoration: none;
	}
}
@media (min-width: 768px) {
	.app-header__logo {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		display: block;
		width: 230px;
	}
}
.app-sidebar__toggle {
	color: $white;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	z-index: 1;
	margin-right: 10px;
	&:hover {
		color: $white;
	}
	&:focus, &:hover {
		text-decoration: none;
	}
}
/*@media (max-width: 360px) {
	.app-sidebar__toggle {
		line-height: 2;
	}
}*/

@media (max-width: 767px) {
	.app-sidebar__toggle {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
	}
	.main-sidebar-header {
		display: none !important;
	}
	.main-sidemenu {
		margin-top: 0 !important
	}
	.main-header-left {
		.left-content {
			display: none;
		}
	}
}
.app-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
@media (min-width: 768px) {
	.app-nav {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
	}
}
.app-nav__item {
	display: block;
	padding: 15px;
	line-height: 20px;
	color: #a8a8a8;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&:hover, &:focus {
		background: $black-1;
		color: #f6f6f6;
	}
}
.app-search {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: center;
	align-self: center;
	margin-right: 15px;
	padding: 10px 0;
}
@media (max-width: 480px) {
	.app-search {
		display: none;
	}
}
.app-search__input {
	border: 0;
	padding: 5px 10px;
	padding-right: 30px;
	border-radius: 2px;
	background-color: $white-8;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&::-webkit-input-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::placeholder {
		color: $black-4;
	}
}
.app-search__button {
	position: absolute;
	right: 0;
	top: 10px;
	bottom: 10px;
	padding: 0 10px;
	border: 0;
	color: $black-8;
	background: none;
	cursor: pointer;
}
.app-notification {
	min-width: 270px;
}
.app-notification__title {
	padding: 8px 20px;
	text-align: center;
	background-color: rgba(0, 150, 136, 0.4);
	color: #333;
}
.app-notification__footer {
	padding: 8px 20px;
	text-align: center;
	background-color: #eee;
}
.app-notification__content {
	max-height: 220px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background: $black-2;
	}
}
.app-notification__item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 8px 20px;
	color: inherit;
	border-bottom: 1px solid #ddd;
	-webkit-transition: background-color 0.3s ease;
	-o-transition: background-color 0.3s ease;
	transition: background-color 0.3s ease;
	&:focus, &:hover {
		color: inherit;
		text-decoration: none;
		background-color: #e0e0e0;
	}
}
.app-notification__message, .app-notification__meta {
	margin-bottom: 0;
}
.app-notification__icon {
	padding-right: 10px;
}
.app-notification__message {
	line-height: 1.2;
}
.app-sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	color: #14112d;
	width: 270px;
	max-height: 100%;
	z-index: 1000;
	background: $white;
	-webkit-box-shadow: 0px 8px 14.72px 1.28px rgba(229, 228, 230, 0.5);
	box-shadow: 0px 8px 14.72px 1.28px rgba(229, 228, 230, 0.5);
	border-right: 1px solid $border;
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background: $black-2;
	}
}
@media print {
	.app-sidebar {
		display: none;
	}
}
@media (max-width: 767px) {
	.app-sidebar__overlay {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 9;
	}
}
.app-sidebar__user {
	.dropdown-menu {
		top: 10px !important;
	}
	img {
		box-shadow: 0 0 25px$white-1;
		border: 2px solid rgb(255, 255, 255);
		box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
	}
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #a8a8a8;
	width: 100%;
	display: inline-block;
}
.app-sidebar__user-avatar {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin-right: 15px;
}
.app-sidebar__user-name {
	&.text-sm {
		font-size: 12px;
		font-weight: 400;
	}
	font-size: 17px;
	line-height: 1.3;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: 0;
	overflow: hidden;
	font-weight: 600;
	color: #e5e9ec;
	font-size: 15px;
	margin-top: 5px !important;
}
.app-sidebar__user-designation {
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	margin-bottom: 0;
	overflow: hidden;
	font-weight: 600;
	color: #e5e9ec;
	font-size: 15px;
	margin-top: 5px !important;
}
.side-menu {
	margin-bottom: 0;
	padding: 0 30px;
	list-style: none;
	padding-bottom: 120px;
	.slide .side-menu__item {
		padding: 0 30px 0 0px;
	}
}
slide is-expanded side-menu__item {
	background: rgba(238, 238, 247, 0.9);
}
.slide {
	margin: 0 0 0px 0;
}
.side-menu .slide.active {
	.side-menu__item {
		background-color: transparent;
	}
	.side-menu__label, .side-menu__icon {
		color: $white;
	}
}
.side-menu__item {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	-webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
	-o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
	transition: border-left-color 0.3s ease, background-color 0.3s ease;
	/* color:$white; */
	height: 45px;
	border-radius: 5px;
	.angle {
		color: $white-8 !important;
	}
	&.active, &:hover, &:focus {
		text-decoration: none;
	}
	&.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
		color: $white;
	}
}
@media (min-width: 768px) {
	.app.sidebar-mini.sidenav-toggled .profile-status {
		right: 22px;
		width: 11px;
		height: 11px;
		top: 57px;
	}
	.app.sidenav-toggled .side-menu__label {
		display: none !important;
		position: relative;
		padding: 0;
		min-width: 100%;
		margin: 0;
		left: 0;
		color: $white-8;
		opacity: 1;
		background: transparent;
		font-size: 12px;
		box-shadow: none;
	}
}
.slide {
	&:hover {
		.side-menu__label, .angle, .side-menu__icon {
			color: $white !important;
		}
	}
	&.is-expanded {
		.side-menu__label, .side-menu__icon, .angle {
			color: $white;
		}
	}
}
.slide-item {
	&.active, &:hover, &:focus {
		text-decoration: none;
		color: #b5c1d2;
	}
	&.active, &:hover, &:focus {
		text-decoration: none;
		color: $white !important;
	}
}
.slide-menu {
	.sub-slide {
		margin: 5px 12px 5px 6px;
	}
	.sub-slide-menu {
		padding: 0 23px;
	}
}
.sub-slide-menu li {
	margin: 7px 0;
}
.sub-slide .sub-angle {
	margin-left: auto;
	float: right;
	font-size: 12px;
	margin-top: 3px;
}
.slide-menu li {
	position: relative;
}
.slide.is-expanded {
	a {
		color: $white-8;
		text-decoration: none;
	}
	.sub-side-menu__item:before {
		content: "\e92f";
		font-family: 'feather' !important;
		position: absolute;
		top: 9px;
		left: 16px;
		font-size: 9px;
	}
}
.side-menu .side-menu__icon {
	font-size: 24px;
	line-height: 0;
	margin-right: 6px;
	width: 24px;
	height: 24px;
	line-height: 30px;
	border-radius: 3px;
	text-align: center;
	color: $white-7;
	fill: $white-7;
}
.side-menu__icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: 25px;
}
.side-menu__label {
	white-space: nowrap;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	/* text-transform: uppercase; */
	/* font-size: 12px; */
	/* font-weight: 500; */
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	white-space: nowrap;
	color: $white-7;
	position: relative;
	font-size: 0.875rem;
	line-height: 1;
	vertical-align: middle;
	font-weight: 400;
}
.slide.is-expanded .slide-menu {
	max-height: 100vh;
}
.slide-menu {
	max-height: 0;
	overflow: hidden;
	padding: 0;
	padding-left: 0;
	list-style: none;
	-webkit-transition: max-height .3s ease-out;
	-o-transition: max-height .3s ease-out;
	transition: max-height .3s ease-out;
}
.slide.is-expanded {
	.slide-menu {
		padding: 0px 0 10px 0;
		max-height: 200vh;
		-webkit-transition: max-height .75s ease-in;
		-o-transition: max-height .75s ease-in;
		transition: max-height .75s ease-in;
	}
}
.slide-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	/* padding: 6px 7px; */
	font-size: 13px;
	height: 32px;
	padding: 0 0 0 51px;
	font-weight: 400;
	margin: 4px 0;
	.icon {
		margin-right: 5px;
	}
}
.angle {
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: -webkit-transform 0.3s ease;
	-o-transition: transform 0.3s ease;
	transition: transform 0.3s ease;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
@media (max-width: 848px) {
	.profile-user .p-text {
		display: none;
	}
}
@media (min-width: 768px) {
	.sidenav-toggled {
		.app-sidebar__user-name, .app-sidebar__user-designation, .angle, .app-sidebar__user-name, .user-notification, .app-sidebar__user-name, .user-info {
			display: none;
		}
		.sidenav-toggled.user-notification::before {
			background: transparent;
			display: none;
		}
		.app-sidebar__user-name, .avatar-xl {
			width: 3rem;
			height: 3rem;
			line-height: 3rem;
			font-size: 1rem;
			margin-bottom: 0px !important;
		}
		.app-sidebar__user, .side-menu {
			margin-top: 0px;
		}
		.app-sidebar__user-avatar {
			width: 25px;
			height: 25px;
		}
		.side-menu li .side-menu__item.active:before {
			display: none;
		}
		.app-sidebar__user {
			padding: 12px 0px 12px 0;
			margin-bottom: 0px;
			border-bottom: 1px solid rgba(225, 225, 225, 0.05);
		}
		.profile-img {
			top: 0px;
			right: 19px;
		}
		.app-content {
			margin-left: 0;
		}
		.app-sidebar {
			left: 0;
			&:hover {
				overflow: visible;
			}
		}
		.side-menu__item {
			overflow: hidden;
			&:hover {
				overflow: visible;
				.side-menu__label {
					opacity: 1;
				}
				+.slide-menu {
					visibility: visible;
				}
			}
		}
		.side-menu__label {
			display: block;
			position: absolute;
			top: 0;
			left: 50px;
			min-width: 180px;
			padding: 12px 5px 12px 20px;
			margin-left: -3px;
			line-height: 1;
			opacity: 0;
			background: #ffffff;
			color: #a8a8a8;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
		.slide {
			&:hover {
				.side-menu__label {
					opacity: 1;
				}
				.slide-menu {
					max-height: 100%;
					opacity: 1;
					visibility: visible;
					z-index: 10;
				}
			}
			.side-menu__label {
				border-bottom-right-radius: 0;
			}
		}
		.slide-menu {
			position: absolute;
			left: 90px;
			min-width: 180px;
			opacity: 0;
			border-bottom-right-radius: 4px;
			z-index: 9;
			visibility: hidden;
			-webkit-transition: visibility 0.3s ease;
			-o-transition: visibility 0.3s ease;
			transition: visibility 0.3s ease;
			-webkit-box-shadow: 0px 8px 17px $black-2;
			box-shadow: 0px 8px 17px $black-2;
		}
	}
	.app.sidenav-toggled {
		.side-menu__item {
			display: block;
			padding: 10px 0;
			margin: 0 auto;
			text-align: center;
			border-left: 0;
			&.active {
				.side-arrow {
					display: none;
				}
			}
		}
		.side-menu_label {
			display: block;
			font-size: 12px;
		}
		.side-menu__label {
			display: block;
			position: relative;
			padding: 0;
			min-width: 100%;
			margin: 0;
			left: 0;
			color: #5c6287;
			opacity: 1;
			background: transparent;
			font-size: 12px;
			box-shadow: none;
		}
	}
	.sidenav-toggled .app-sidebar__user .avatar-md {
		margin: 0 auto;
	}
	.app.sidenav-toggled .nav-badge {
		position: absolute;
		top: 8px;
		right: 28px;
		padding: 0.2rem 0.4rem;
		font-size: 11px;
	}
}
@media (min-width:767px) {
	.app {
		&.sidenav-toggled .side-menu .side-menu__icon {
			margin-right: 0;
			margin-left: 0;
		}
		.side-menu_label {
			display: none;
		}
	}
}
.dropdown-menu {
	border-radius: 0;
	&.dropdown-menu-right {
		left: auto;
	}
}
.dropdown-item {
	.fa, .icon {
		vertical-align: middle;
	}
}
.app-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: $white;
	margin: -30px -30px 30px;
	padding: 20px 30px;
	-webkit-box-shadow: 0 1px 2px $black-1;
	box-shadow: 0 1px 2px $black-1;
	h1 {
		margin: 0;
		font-size: 24px;
		font-weight: 400;
	}
	p {
		margin-bottom: 0;
		font-style: italic;
	}
}
@media print {
	.app-title {
		display: none;
	}
}
@media (max-width: 480px) {
	.app-title {
		margin: -15px -15px 15px;
		padding: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
}
@media (max-width: 480px) {
	.app-title p {
		display: none;
	}
}
.app-breadcrumb {
	margin-bottom: 0;
	text-align: right;
	font-weight: 500;
	font-size: 13px;
	text-transform: capitalize;
	padding: 0;
	text-align: left;
	padding: 0;
	background-color: transparent;
}
@media (max-width: 480px) {
	.app-breadcrumb {
		margin-top: 10px;
	}
}
.tile {
	position: relative;
	background: #ffffff;
	border-radius: 3px;
	padding: 20px;
	-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px $black-2;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px $black-2;
	margin-bottom: 30px;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
@media print {
	.tile {
		border: 1px solid #ddd;
	}
}
.sidenav-toggled .app-sidebar__user .avatar-md {
	line-height: 2rem;
	font-size: 1rem;
}
.app-sidebar {
	.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background: $black-05;
	}
	.mCSB_scrollTools {
		.mCSB_dragger .mCSB_dragger_bar, .mCSB_draggerRail {
			background: $white-2;
		}
	}
}
.sidenav-toggled .main-wrapper .side-menu .side-menu__item {
	.nav-badge {
		position: absolute;
		top: 5px;
		left: 57px;
		display: block !important;
		padding: 3px 5px !important;
	}
	.nav-badge1 {
		display: none;
	}
}
.nav-badge {
	border-radius: 30px;
	padding: 0.4em 0.6em;
	font-size: 12px;
}
.user-info {
	.text-dark {
		color: #fff !important;
		font-weight: 400;
		font-size: 16px;
	}
	.text-muted {
		color: $white-4 !important;
	}
}
.side-header {
	.header-brand1 {
		text-align: center;
		display: table;
	}
	.header-brand-img.desktop-logo {
		max-height: 2.5rem;
		text-align: center;
		display: block;
		margin-right: 0;
	}
}
.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo, .side-header .header-brand-img.toggle-logo {
	display: none;
}
.sidenav-toggled {
	.app-sidebar .side-header {
		.header-brand-img.toggle-logo {
			display: none;
			margin-right: 0;
		}
	}
	.user-pic {
		margin-bottom: 0;
	}
	.sidebar-navs {
		display: none;
	}
}
.side-header {
	display: flex;
	border-bottom: 1px solid$white-1;
	padding: 0;
	-webkit-transition: left 0.3s ease, width 0.3s ease;
	-o-transition: left 0.3s ease, width 0.3s ease;
	transition: left 0.3s ease, width 0.3s ease;
}
.sidenav-toggled .app-sidebar__toggle {
	display: block;
}
.user-pic {
	margin-bottom: 0.8rem;
}
.sidebar-navs a {
	background: rgba(255, 255, 255, 0.07);
	border: 1px solid rgba(255, 255, 255, 0.07) !important;
	color: #fff !important;
	border-radius: 5px;
	padding: 0.8rem !important;
}
.app-sidebar .sidebar-navs {
	padding: 0 10px 10px 10px;
	border-bottom: 1px solid$white-1;
}
.sidebar-navs .nav li:last-child a {
	margin-right: 0 !important;
}
.side-menu h3 {
	font-size: 11px;
	font-weight: 400;
	letter-spacing: 1px;
	opacity: 1;
	padding: 25px 31px 5px;
	text-transform: capitalize;
	width: 100%;
	position: relative;
	color: $white-6;
	margin: 0;
	/* color: $white-4!important; */
	position: relative;
	&:after {
		content: "\e0e7";
		position: absolute;
		left: 13px;
		top: 25px;
		font-size: 10px;
		font-family: 'typicons';
	}
}
.sidenav-toggled .side-menu h3 {
	display: none;
}
.slide.is-expanded .slide-menu, .sub-slide {
	position: relative;
}
/*-- Sub-slide--**/

.sub-side-menu__item {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 7px 0;
	font-size: 14px;
}
.sub-side-menu__label {
	white-space: nowrap;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	font-weight: 400;
}
.sub-slide {
	.sub-angle {
		transform-origin: center;
		opacity: 0.5;
	}
	&.is-expanded .sub-angle {
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}
}
.sub-slide-menu {
	list-style: none;
	padding: 0;
}
.sub-slide-item {
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	list-style: none;
	height: 30px;
	padding: 0 0 0 25px !important;
}
.sub-slide-menu {
	max-height: 0;
	overflow: hidden;
	padding: 0;
	font-size: .8rem !important;
	padding-left: 0;
	list-style: none;
	-webkit-transition: max-height .3s ease-in-out;
	-o-transition: max-height .3s ease-in-out;
	transition: max-height .3s ease-in-out;
}
.sub-slide.is-expanded {
	.sub-slide-menu {
		max-height: 150vh;
		webkit-transition: max-height 1s ease-in-out;
		-o-transition: max-height 1s ease-in-out;
		transition: max-height 1s ease-in-out;
	}
	.sub-side-menu__item {
		color: $white;
	}
}
.slide-menu .sub-slide.is-expanded {
	max-height: 150vh;
	-webkit-transition: max-height 2s ease;
	-o-transition: max-height 2s ease;
	transition: max-height 2s ease;
}
.sub-side-menu__item {
	padding-left: 45px !important;
	padding-right: 20px !important;
	height: 35px !important;
}
.sub-slide-item {
	padding-left: 42px !important;
	height: 28px !important;
}
.app-sidebar {
	.side-menu__item.active:hover {
		color: $white !important;
	}
	.slide {
		&.active .side-menu__icon, &.is-expanded .side-menu__icon {
			fill: $white !important;
		}
		&.active {
			.side-menu__icon {
				fill: $white !important;
			}
			.side-menu__item {
				color: $white !important;
			}
		}
	}
}
.side-menu .slid.active .sub-slide.is-expanded .sub-slide-menu {
	max-height: 0;
}
.app-sidebar .slide-menu a:before {
	content: "\e92f";
	font-family: 'feather' !important;
	position: absolute;
	top: 9px;
	left: 23px;
	font-size: 9px;
}
.app-sidebar__toggle .close-toggle, .sidebar-mini.sidenav-toggled .open-toggle {
	display: none;
}
.header-icon {
	text-align: center;
	width: 34px;
	height: 34px;
	border-radius: 4px;
	text-align: center;
	line-height: 34px;
	font-size: 1.2rem;
	color: $black;
}
.sidebar-mini.sidenav-toggled .close-toggle {
	display: block;
	color: #000;
	font-size: 18px;
}
.sidenav-toggled .app-content {
	transition: margin-left 0.3s ease;
}
.sub-slide-menu .active .sub-slide-item.active {
	color: $danger;
}
.slide.is-expanded .side-menu__item {}
.side-badge {
	position: absolute;
	right: 50px;
	top: 11px;
	display: none !important;
}
.side-menu__item:hover .side-badge {
	display: inline-block !important;
}
.app-sidefooter {
	border-top: 1px solid $white-1;
	position: fixed;
	bottom: 0;
	width: 270px;
	padding: 10px 0;	
	a {
		color: $white-7 !important;
		fill: $white-7 !important;
		padding: 5px 25px;
		.side-menu__icon {
			margin-right: 6px;
		}
	}
}
.side-menu__item {
	&.active {
		.side-arrow {
			position: absolute;
			width: 25px;
			right: -34px;
			background: #f4f5fd;
			height: 23px;
			top: 4px;
			border-radius: 50% 0 0 50%;
		}
		.side-angle1 {
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 0 20px 20px 0;
			border-color: transparent #f2f4fd transparent transparent;
			position: absolute;
			right: -30px;
			top: -15px;
			transform: rotate(90deg);
			&:after {
				content: "";
				display: block;
				width: 20px;
				height: 20px;				
				border-radius: 0 83% 0 0;
				left: 0px;
				position: absolute;
			}
		}
		.side-angle2 {
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 0 20px 20px 0;
			border-color: transparent #f2f4fd transparent transparent;
			position: absolute;
			right: -30px;
			top: 26px;
			&:after {
				content: "";
				display: block;
				width: 20px;
				height: 20px;
				border-radius: 0 83% 0 0;
				left: 0px;
				position: absolute;
			}
		}
	}
}
.app-sidebar {
	.ps>.ps__rail-y {
		width: 0px;
	}
}